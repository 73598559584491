import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const LevelIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.light} {...props}>
    <path d="M109.52 125.49h7v-13.56h7.76a6.14 6.14 0 0012.26 0h7.75v13.56h7v-13.56h8.88a3.5 3.5 0 100-7h-59.51a3.5 3.5 0 100 7h8.86zm99.69-35.92h-13.93a3.49 3.49 0 00-3.49 3.49v4h-16.88a3.48 3.48 0 00-3.48 3.48v17.07a15.69 15.69 0 01-15.69 15.69h-50.72a15.69 15.69 0 01-15.69-15.69v-17.03a3.48 3.48 0 00-3.49-3.48H68.96v-4a3.49 3.49 0 00-3.49-3.49H51.54a3.49 3.49 0 00-3.49 3.49v74.64a3.49 3.49 0 003.49 3.49h13.89a3.49 3.49 0 003.49-3.49v-4h122.87v4a3.49 3.49 0 003.49 3.49h13.93a3.49 3.49 0 003.49-3.49V93.06a3.49 3.49 0 00-3.49-3.49M61.99 164.21h-7V96.53h7zm129.8-7.41H68.96v-52.73h13.4v13.58a22.66 22.66 0 0022.65 22.66h50.72a22.67 22.67 0 0022.7-22.66v-13.58h13.39zm13.94 7.41h-7V96.53h7z" />
  </svg>
)

export default LevelIcon
