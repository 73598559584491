import React, { ReactNode } from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"

import { ContentfulImage } from "src/types"
import { ResponsiveImageSwitcher } from "src/components/Image"

const Container = styled.section`
  position: relative;
`
const Image = styled(GatsbyImage)`
  max-height: 80vh;
`
const Gif = styled.img`
  width: 100%;
  max-height: 80vh;
  overflow: hidden;
  object-fit: cover;
  object-position: center center;
`
const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

interface GifProps {
  src: ContentfulImage
  webp?: ContentfulImage
  mobile?: ContentfulImage
  mobileWebp?: ContentfulImage
  children?: ReactNode
  className?: string
}

const Featured: React.FC<GifProps> = ({ src, webp, mobile, mobileWebp, children, className, ...props }) => {
  const { fluid, file } = src // file => GIF, fluid => Image

  if (fluid && !webp) {
    return (
      <Container className={className}>
        <Image fluid={fluid} />
        <Content>{children}</Content>
      </Container>
    )
  }

  // TODO: use IntersectionObserver to enforce loading="lazy"
  return (
    <Container className={className}>
      <ResponsiveImageSwitcher>
        <picture className={mobile && "desktop"}>
          {webp && <source srcSet={webp.file.url} type="image/webp" />}
          <source srcSet={file.url} type="image/gif" />
          <Gif src={file.url} loading="lazy" alt="" {...props} />
        </picture>
        {mobile && (
          <picture className="mobile">
            {mobileWebp && <source srcSet={mobileWebp.file.url} type="image/webp" />}
            <source srcSet={mobile.file.url} type="image/gif" />
            <Gif src={mobile.file.url} loading="lazy" alt="" {...props} />
          </picture>
        )}
      </ResponsiveImageSwitcher>
      <Content>{children}</Content>
    </Container>
  )
}

export default Featured
