import styled from "styled-components"

import { SIZES, BREAKPOINTS } from "src/constants"

interface GalleryProps {
  columns: number
}

const calc = (props: GalleryProps): number => 100 / props.columns

const Gallery = styled.div<GalleryProps>`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  figure,
  a {
    flex: 0 1 calc(${calc}% - 60px);
    margin: 30px;
  }
  img {
    width: 100%;
  }
  figcaption {
    font-weight: bold;
    margin: 8px 0 2px;
  }
  div {
    font-style: italic;
    white-space: pre-line;
    word-break: break-all;
  }
  figcaption,
  div {
    font-size: ${SIZES.normal};
    line-height: ${SIZES.normal};
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 10px -10px;
    figure,
    a {
      flex: 0 1 calc(${calc}% - 20px);
      margin: ${(props): string => (props.columns === 2 ? "20px 10px" : "10px")};
    }
    div {
      font-size: 0.9em;
    }
  }
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    ${(props): string => (props.columns === 2 ? "display: block;" : "")}
  }
`

export default Gallery
