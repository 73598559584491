import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { IWorkshop } from "src/types"
import Image, { ResponsiveImageSwitcher } from "src/components/Image"
import { COLORS, SIZES, BREAKPOINTS } from "src/constants"
import RulerIcon from "src/svg/icon/Ruler"
import { formatMoney } from "src/helpers/text"

const Block = styled.div`
  position: relative;
  background-color: ${COLORS.placeholder};
`
// https://github.com/styled-components/styled-components/issues/439
const Container = styled(Link)<{ "data-wide": boolean }>`
  flex: 0 1 ${(props): string => (props["data-wide"] ? "100%" : "calc(50% - 60px)")};
  display: block;
  margin: 30px;
  ${Block} {
    padding-bottom: ${(props): string => (props["data-wide"] ? "48%" : "100%")};
    @media (max-width: ${BREAKPOINTS.tablet}px) {
      padding-bottom: 100%;
    }
  }
`
const Picture = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
  }
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    display: none;
  }
`
const Icon = styled.div`
  svg {
    width: 200px;
    height: 200px;
  }
`
const Name = styled.h3`
  text-transform: uppercase;
  color: ${COLORS.main};
  font-size: ${SIZES.huge};
  margin: 0 20px;
  max-width: 400px;
`
const Caption = styled.h4`
  margin: 10px 0 0;
  font-size: ${SIZES.normal};
`
const Summary = styled.p`
  margin: 5px 0;
`

const Workshop: React.FC<{ data: IWorkshop }> = ({
  data: { slug, picture, largepic, name, icon, summary, price, isStartPrice, wide },
}) => {
  return (
    <Container data-wide={wide} to={`/ateliers/${slug}/`}>
      <Block>
        {picture && <Picture file={wide ? largepic : picture} />}
        <Overlay>
          <Icon dangerouslySetInnerHTML={{ __html: icon.svg.content }} />
          <Name>{name}</Name>
        </Overlay>
      </Block>
      <Caption>
        {name} • {isStartPrice ? "Dès " : ""}
        {price ? formatMoney(price) : "gratuit"}
      </Caption>
      <Summary>{summary}</Summary>
    </Container>
  )
}

export const CustomWorkshop: React.FC = () => {
  const { desktop, mobile } = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "home/group-desktop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1160) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mobile: file(relativePath: { eq: "home/group-mobile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container data-wide={true} to="/groupes-entreprises/">
      <Block>
        <ResponsiveImageSwitcher>
          <Picture file={desktop.childImageSharp} className="desktop" />
          <Picture file={mobile.childImageSharp} className="mobile" />
        </ResponsiveImageSwitcher>
        <Overlay>
          <RulerIcon width={200} height={200} />
          <Name>Groupes et entreprises</Name>
        </Overlay>
      </Block>
      <Caption>Groupes et entreprises • sur devis</Caption>
      <Summary>évènements, entreprises, groupes, équipes… créer et construire ensemble vos projets</Summary>
    </Container>
  )
}

export default Workshop
