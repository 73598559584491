import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const BuilderIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="270 130 300 333" width={90} height={100} fill={COLORS.main} {...props}>
    <path d="M389.53 325.18a13.64 13.64 0 10-27.29-.01 13.64 13.64 0 0027.29.01m155.26-42.38a8.04 8.04 0 002.92-6.18v-25.24a8.06 8.06 0 00-7.09-7.98l-10.13-1.2a110.43 110.43 0 00-39.81-71.03c-5.29-4.32-13.24-.63-13.24 6.2a8.07 8.07 0 003.05 6.24 94.18 94.18 0 0134.64 66.36c.28 3.91 3.43 7 7.32 7.46l9.2 1.09v11.72c-35.1 7.9-72.31 11.92-110.69 11.92-38.4 0-75.61-4.02-110.71-11.92v-11.72l9.21-1.09a8.22 8.22 0 007.31-7.46 94.04 94.04 0 0134.64-66.35 8.09 8.09 0 003.05-6.24c0-6.82-7.95-10.52-13.24-6.2a110.39 110.39 0 00-39.79 71.03l-10.14 1.2a8.04 8.04 0 00-7.09 7.98v25.24a8.03 8.03 0 002.93 6.18 23.68 23.68 0 00-18.06 26.99l6.04 35.81a23.6 23.6 0 0030.72 18.52c6.94 29.2 19.18 52.22 36.68 68.76 29.36 27.77 63.05 27.05 68.45 26.76.62.04 1.63.09 2.97.09 10.21 0 39.54-2.32 65.48-26.85 17.5-16.54 29.74-39.59 36.68-68.77a23.16 23.16 0 007.4 1.23c11.58 0 21.4-8.31 23.33-19.76l6.02-35.79a23.69 23.69 0 00-18.05-27m-235.06 66.36a7.54 7.54 0 01-8.8-6.23l-6.04-35.82a7.62 7.62 0 0114.2-4.87 302.1 302.1 0 003.56 45.75c-.85.58-1.83.99-2.92 1.17m168.87 71.82c-25.95 24.74-56.7 22.63-56.96 22.63-.22-.03-.45-.03-.68-.03l-.67.01c-.32.04-31.03 2.11-57-22.61-26.89-25.63-39.66-69.94-38.02-131.33a527.6 527.6 0 0095.69 8.56c32.9 0 64.94-2.89 95.66-8.56 1.6 61.55-11.16 105.74-38.02 131.33M547 307.11l-6.03 35.8a7.57 7.57 0 01-8.79 6.24 7.28 7.28 0 01-2.93-1.16 302.87 302.87 0 003.55-45.78 7.57 7.57 0 017.95-3.88 7.61 7.61 0 016.25 8.78m-81 4.42a13.65 13.65 0 000 27.28 13.63 13.63 0 100-27.28m-21.77 71.28c-5.3 3.59-13.99 5.72-23.27 5.72-9.29 0-17.99-2.14-23.29-5.71a8.05 8.05 0 00-11.15 2.16 8.04 8.04 0 002.18 11.15c7.96 5.37 19.7 8.44 32.26 8.44 12.53 0 24.29-3.07 32.25-8.43a8.04 8.04 0 002.18-11.15 8.09 8.09 0 00-11.16-2.18m-43.65-223.25c0-4.4 3.57-7.96 7.97-7.96h24.8a7.94 7.94 0 017.94 7.95v76.39a8.04 8.04 0 0016.07 0v-76.38a24.01 24.01 0 00-24.03-24.01h-24.79a24.01 24.01 0 00-24.03 24.01v76.38a8.04 8.04 0 0016.06 0v-76.38z" />
  </svg>
)

export default BuilderIcon
