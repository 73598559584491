import React from "react"
import Image from "gatsby-image"

import { InstagramPost } from "src/types"
import Gallery from "src/components/Gallery"

interface Props {
  data: {
    nodes: InstagramPost[]
  }
}

const Instagram: React.FC<Props> = ({ data }) => (
  <Gallery columns={3}>
    {data.nodes.map(({ id, link, caption, image }) => (
      <a key={id} href={link} target="_blank" rel="noopener" title={caption}>
        <Image {...image.childImageSharp} alt="" />
      </a>
    ))}
  </Gallery>
)

export default Instagram
