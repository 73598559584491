import React, { useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import { GatsbyPage, IWorkshop, IPartner, IFeatured } from "src/types"
import Layout from "src/layout"
import Image, { RectImage } from "src/components/Image"
import {
  COLORS,
  EMAIL_ADDRESS,
  INSTAGRAM,
  FACEBOOK,
  LINKEDIN_URL,
  PINTEREST,
  SIZES,
  BREAKPOINTS,
  GOOGLE_MAPS,
} from "src/constants"

import Logo from "src/svg/Logo"
import LevelIcon from "src/svg/icon/Level"
import MaterialsIcon from "src/svg/icon/Materials"
import DrillIcon from "src/svg/icon/Drill"
import ScrewIcon from "src/svg/icon/Screw"
import BuilderIcon from "src/svg/icon/Builder"

import Workshop, { CustomWorkshop } from "src/components/Workshop"
import Instagram from "src/components/Instagram"
import { Splash, Parallax } from "src/components/Parallax"
import Featured from "src/components/Featured"
import {
  Section,
  Title,
  Description,
  Subtitle,
  Features,
  Feature,
  FeatCaption,
  SectionTitle,
  InternalCta,
  InfosContainer,
  Infos,
  InfosImage,
  InfosContent,
  InfosTitle,
  InfosSubtitle,
  InfosText,
  Content,
} from "src/components/styled"
import { smoothScroll } from "src/helpers/window"

const Center = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const BigLogo = styled(Logo)`
  pointer-events: none; /* prevent title tooltip */
  width: 800px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    width: 80vw;
  }
`
const Tagline = styled.h2`
  color: ${COLORS.light};
  margin: 50px 20px 0;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.normal};
    line-height: 1.5em;
  }
`

const Illustration = styled(Image)`
  max-width: 700px;
  margin: 20px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 0;
  }
`
const Desc = styled(Description)`
  max-width: ${BREAKPOINTS.maxTextWidth}px;
`
const Text = styled.p`
  font-size: ${SIZES.medium};
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    font-size: ${SIZES.small};
  }
  font-weight: bold;
  margin: 0;
`

const Workshops = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px 60px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    display: block;
  }
`

const News = styled(Featured)`
  margin: 180px 0 100px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 50px 0 50px;
  }
`
const NewsTitle = styled(Title)`
  max-width: 770px;
  margin: 80px;
  @media (max-width: ${BREAKPOINTS.tablet}px) {
    margin: 20px;
  }
`

const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
const Partner = styled(RectImage)`
  width: 17%;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    width: 27%;
  }
`

const IndexPage: GatsbyPage<{ anchor: string }> = ({ data, location: { state } }) => {
  const contact = useRef<HTMLElement>()
  useEffect(() => {
    if (state && state.anchor === "contact") {
      setTimeout(smoothScroll, 50, contact.current, -90, 1500)
    }
  }, [state])

  return (
    <Layout>
      <Splash>
        <Parallax desktop={data.splashDesktop.childImageSharp} mobile={data.splashMobile.childImageSharp} />
        <Center>
          <BigLogo />
          <Tagline>Ateliers de fabrication d’objets en chutes de bois</Tagline>
        </Center>
      </Splash>
      <Content>
        <Section>
          <Title space>
            <span>Des ateliers pour construire ses objets à partir de chutes de bois</span>
          </Title>
          <Features>
            <Feature>
              <LevelIcon />
              <FeatCaption>ouvert à tous les niveaux</FeatCaption>
            </Feature>
            <Feature>
              <MaterialsIcon />
              <FeatCaption>matériaux de réemploi fournis</FeatCaption>
            </Feature>
            <Feature>
              <DrillIcon />
              <FeatCaption>accès aux outils inclus</FeatCaption>
            </Feature>
            <Feature>
              <ScrewIcon />
              <FeatCaption>consommables de bases inclus</FeatCaption>
            </Feature>
          </Features>
          <Desc>
            Nous collectons des chutes de bois auprès des artisans et des musées parisiens et nous les mettons à votre
            disposition pour la réalisation de vos objets. L’atelier est installé au Viaduc des Arts à Paris (12e).
            Venez l’esprit libre, ici tout est inclus : l’accès aux outils et aux machines, les consommables, et bien
            sûr les chutes de bois. Nous animons des ateliers toutes les semaines permettant, même si vous êtes
            débutant·e en bricolage, de suivre nos modèles ou de réaliser vos projets.
          </Desc>
          <Illustration
            file={data.steps.childImageSharp}
            alt="3 étapes : des chutes de bois ; des idées, des outils, des amis ; un meuble à rapporter chez soi"
          />
        </Section>
        {data.featured.nodes.map((article: IFeatured) => (
          <News
            key={article.id}
            src={article.image}
            webp={article.webp}
            mobile={article.mobileImage}
            mobileWebp={article.mobileWebp}
          >
            <NewsTitle>
              <span>{article.title}</span>
            </NewsTitle>
          </News>
        ))}
        <Section>
          <Subtitle>NOS ATELIERS</Subtitle>
          <Text>Plusieurs formats adaptés à vos envies.</Text>
          <Workshops>
            {data.workshops.nodes.map((node: IWorkshop) => (
              <Workshop key={node.slug} data={node} />
            ))}
            <CustomWorkshop />
          </Workshops>
        </Section>
        <Center>
          <InternalCta to="/offrir/">
            <BuilderIcon />
            <span>
              Offrir
              <br />
              un atelier
            </span>
          </InternalCta>
        </Center>
        <InfosContainer ref={contact}>
          <Infos>
            <InfosImage file={data.infos.childImageSharp} alt="" />
            <InfosContent>
              <InfosTitle>Infos pratiques</InfosTitle>
              <InfosSubtitle>L’atelier est installé</InfosSubtitle>
              <InfosText>
                <a href={GOOGLE_MAPS} target="_blank" rel="noopener">
                  au Viaduc des Arts
                  <br />
                  13 avenue Daumesnil
                  <br />
                  75012 Paris
                </a>
              </InfosText>
              <InfosSubtitle>Contactez-nous</InfosSubtitle>
              <InfosText>
                Merci de consulter les{" "}
                <Link to="/faq/">
                  <u>questions fréquentes</u>
                </Link>{" "}
                avant de nous contacter.
                <br />
                <br />
                Par e-mail : <a href={`mailto:${EMAIL_ADDRESS}`}>{EMAIL_ADDRESS}</a>
                <br />
                Par téléphone : <a href={`tel:+33749308065`}>07 49 30 80 65</a>
              </InfosText>
              <InfosSubtitle>Suivez-nous</InfosSubtitle>
              <InfosText>
                <a href={`https://www.instagram.com/${INSTAGRAM}/`}>Instagram</a>
                <br />
                <a href={`https://www.facebook.com/${FACEBOOK}/`}>Facebook</a>
                <br />
                <a href={LINKEDIN_URL}>LinkedIn</a>
                <br />
                <a href={`https://www.pinterest.fr/${PINTEREST}/`}>Pinterest</a>
              </InfosText>
            </InfosContent>
          </Infos>
        </InfosContainer>
        <Section>
          <SectionTitle>Pendant ce temps aux ateliers :</SectionTitle>
          <Text>
            Suivez l’actualité des ateliers sur Instagram{" "}
            <a href={`https://www.instagram.com/${INSTAGRAM}/`}>@{INSTAGRAM}</a>
          </Text>
          <Instagram data={data.instagram} />
        </Section>
        <Section>
          <SectionTitle>Ils nous font confiance :</SectionTitle>
          <Partners>
            {data.partners.nodes.map(({ id, logo, name }: IPartner) => (
              <Partner key={id} ratio={2} file={logo} alt={name} />
            ))}
          </Partners>
        </Section>
      </Content>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    splashDesktop: file(relativePath: { eq: "home/splash-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, traceSVG: { background: "#B6ADAA", color: "#9C463A" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    splashMobile: file(relativePath: { eq: "home/splash-mobile.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 800, traceSVG: { background: "#B6ADAA", color: "#9C463A" }) {
          ...GatsbyImageSharpFluid_tracedSVG
          aspectRatio
        }
      }
    }
    steps: file(relativePath: { eq: "home/steps.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, traceSVG: { background: "#FFFFFF", color: "#E10F14" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    infos: file(relativePath: { eq: "infos.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, traceSVG: { background: "#E10F14", color: "#FFFFFF" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    featured: allContentfulFeatured {
      nodes {
        id: contentful_id
        title
        image {
          file {
            url
          }
          fluid(maxWidth: 1440) {
            ...GatsbyContentfulFluid
          }
        }
        webp {
          file {
            url
          }
        }
        mobileImage {
          file {
            url
          }
        }
        mobileWebp {
          file {
            url
          }
        }
      }
    }
    workshops: allContentfulWorkshop(sort: { fields: [position], order: ASC }) {
      nodes {
        slug
        name
        picture {
          fluid(maxWidth: 550) {
            ...GatsbyContentfulFluid
          }
        }
        largepic: picture {
          fluid(maxWidth: 1160) {
            ...GatsbyContentfulFluid
          }
        }
        icon {
          svg {
            content
          }
        }
        summary
        price
        isStartPrice
        wide
      }
    }
    partners: allContentfulPartner(sort: { fields: [position], order: ASC }) {
      nodes {
        id: contentful_id
        name
        logo {
          fluid(maxWidth: 320) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
    instagram: allInstagramPost(sort: { fields: [date], order: DESC }, limit: 9) {
      nodes {
        id
        link
        image {
          childImageSharp {
            fluid(maxWidth: 350) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        caption
      }
    }
  }
`
