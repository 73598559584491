import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const DrillIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.light} {...props}>
    <path d="M169.64 137.04a160 160 0 00.37 20.07 3.48 3.48 0 003.43 3.18h.1a3.49 3.49 0 003.54-3.76 151.61 151.61 0 01-.34-19.18 3.51 3.51 0 00-3.7-3.65h-.07a3.5 3.5 0 00-3.33 3.32M59.18 91.92H52.5a3.55 3.55 0 10-.07 7.09h6.75c1.86 7.74 10.67 13.56 20 13.56h.93a3.55 3.55 0 000-7.09h-.93c-6.77 0-13.3-4.49-13.3-10s6.53-10 13.3-10h.93a3.55 3.55 0 000-7.1h-.93c-9.3 0-18.11 5.82-20 13.56m85.54 65.19a160.67 160.67 0 00.43-18.49 3.53 3.53 0 00-3.65-3.4h-.07a3.48 3.48 0 00-3.36 3.59 154.12 154.12 0 01-.41 17.72 3.48 3.48 0 003.53 3.76h.1a3.49 3.49 0 003.43-3.18m40.5-91.24c-1.76-.1-43.06-2.43-53.64-2.43h-1.47c-17.17.61-31.49 9.83-32.89 10.75-5 3-6.05 4.78-6.51 7.69l-.13 26 .13 1.1c.46 2.91 1.52 4.72 6.51 7.69 1.4.93 15.72 10.14 32.89 10.75h1.5c10.62 0 51.86-2.33 53.69-2.43a9.18 9.18 0 008.28-9V74.85a9.19 9.19 0 00-8.36-9m1.27 50.21a2 2 0 01-1.67 1.9c-.43 0-42.78 2.42-53.21 2.42h-1.25c-15.61-.56-29.14-9.52-29.28-9.61l-.17-.11a11.82 11.82 0 01-3.09-2.21 3.22 3.22 0 01-.1-.54c0-.1 0-.2-.05-.3v-24.3c0-.11 0-.21.05-.31a4.79 4.79 0 01.08-.5c0-.06.47-.68 3.11-2.24l.17-.12c.14-.09 13.67-9 29.28-9.6h1.22c10.39 0 52.81 2.4 53.15 2.42a2 2 0 011.76 1.9zm-32.07-31.15L175 86.14a3.54 3.54 0 00.42-7.07l-20.57-1.24a3.55 3.55 0 00-.43 7.08m.22 17.24h20.57a3.55 3.55 0 000-7.09h-20.57a3.55 3.55 0 100 7.09m20.58 66.05H139.5a10.72 10.72 0 00-9.83 7.38l-3.57 12.2a7.11 7.11 0 007 9.36h42.09a8.57 8.57 0 008.58-8.57v-11.8a8.58 8.58 0 00-8.58-8.57m1.48 20.37a1.5 1.5 0 01-1.48 1.49h-42.33a2.53 2.53 0 01.05-.29l3.56-12.2a3.61 3.61 0 013-2.27h35.72a1.47 1.47 0 011.48 1.47z" />
  </svg>
)

export default DrillIcon
