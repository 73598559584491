import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const MaterialsIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.light} {...props}>
    <path d="M115.81 66.67v23.91c0 1.86-.86 2.91-2.26 2.83-1.64-.15-2.22-1.23-2.14-2.77V66.67h-6.66v8.44c0 1.45-.59 2.48-2.14 2.53s-2.3-1-2.35-2.55v-8.42h-6.6v23.97c0 1.56-.63 2.71-2.23 2.71s-2.19-1.15-2.19-2.71V66.7h-6.51v125.21h6.51v-15.05c0-1.55.59-2.7 2.21-2.7s2.21 1.2 2.21 2.72v14.98h6.6v-9.43c0-2.75.64-3.8 2.29-3.78s2.2 1 2.2 3.85v9.35h6.61v-24.27a2.05 2.05 0 012.15-2.34c1.43 0 2.15.85 2.23 2.29v24.35h6.62V66.67zm-37.73 62.56V65.67c0-2.94.61-3.56 3.49-3.56h41.91c2.81 0 3.41.61 3.41 3.41v127.54c0 2.9-.53 3.44-3.36 3.44H81.44c-2.8 0-3.36-.57-3.36-3.47v-63.8" />
    <path d="M100.24 133.05c0-3.05-.05-6.06 0-9.12A1.64 1.64 0 0098.8 122a13.17 13.17 0 01-9.61-12.93v-6.45a2.08 2.08 0 012.09-2.4c1.5 0 2.28.91 2.3 2.41v6.07c0 5.14 3 8.57 8 9.27 2.55.35 3.08.91 3.08 3.53v20.27c0 2.17-.66 3.08-2.15 3.15s-2.33-.95-2.35-3.17v-8.7m4.58-37.38a25.68 25.68 0 013.88 1.51 13.21 13.21 0 017.17 12c.05 6.34 0 12.67 0 19 0 .46.14 1-.1 1.3-.61.73-1.33 1.76-2.13 1.95-1.22.2-2.1-.74-2.15-2.09-.05-2.08 0-4.17 0-6.25v-13.68a8.84 8.84 0 00-7.8-9.14c-3-.41-3.35-.79-3.35-3.85v-7c0-1.53.62-2.65 2.25-2.65s2.24 1.12 2.24 2.67v6.25m-15.52 42.57v-10.62c0-1.53.5-2.61 2.11-2.8 1.35-.15 2.29 1 2.29 2.82v21.23c0 1.87-.89 2.9-2.3 2.79-1.67-.15-2.12-1.3-2.1-2.8v-10.62m26.56 11.35v8.31c0 1.55-.6 2.7-2.2 2.7s-2.23-1.08-2.21-2.66v-17c0-1.4.5-2.5 2.08-2.56s2.3 1 2.32 2.56v8.69m-11.04 12.11v7.4c0 1.55-.59 2.65-2.27 2.65s-2.22-1.16-2.22-2.7V154.5c0-1.52.6-2.6 2.18-2.8 1.4-.09 2.28 1 2.3 2.83v7.23" />
    <path d="M102.52 104.67a4.5 4.5 0 11-.15 9 4.65 4.65 0 01-4.35-4.55 4.61 4.61 0 014.5-4.42m-8.95 58.11a23.11 23.11 0 010 2.63 2 2 0 01-2.16 1.88 2 2 0 01-2.12-1.95 49.53 49.53 0 010-4.72 2 2 0 012.1-2.15 2 2 0 012.24 2c.06.75 0 1.52 0 2.27zm52.31 29.07v-23.9c0-1.86.87-2.91 2.27-2.83 1.63.15 2.22 1.23 2.13 2.77v23.95h6.67v-8.44c0-1.45.58-2.48 2.14-2.53s2.3 1 2.34 2.55v8.4h6.6v-23.97c0-1.56.64-2.71 2.24-2.71s2.18 1.15 2.18 2.71v23.94h6.52V66.67h-6.51v15.05c0 1.55-.58 2.7-2.2 2.7s-2.22-1.2-2.22-2.72V66.67h-6.6v9.43c0 2.75-.63 3.8-2.28 3.78s-2.2-1.05-2.2-3.85v-9.36h-6.67v24.35a2 2 0 01-2.15 2.33c-1.44 0-2.15-.85-2.24-2.28v-24.4h-6.54v125.2zm37.73-62.55v63.6c0 2.94-.61 3.55-3.5 3.55h-41.9c-2.82 0-3.42-.6-3.42-3.4V65.5c0-2.9.54-3.44 3.37-3.44h42.09c2.8 0 3.36.57 3.36 3.47v63.8" />
    <path d="M161.44 125.51c0 3.05.05 6.06 0 9.11a1.64 1.64 0 001.43 1.94 13.17 13.17 0 019.62 12.93v6.45a2.08 2.08 0 01-2.08 2.4 2.15 2.15 0 01-2.3-2.42v-6.06c0-5.14-3-8.57-8-9.27-2.55-.35-3.09-.92-3.09-3.53v-20.27c0-2.17.67-3.08 2.15-3.15s2.34.95 2.35 3.16v8.71m-4.57 37.35a24.29 24.29 0 01-3.88-1.52 13.17 13.17 0 01-7.17-12c-.05-6.34 0-12.67 0-19 0-.46-.13-1 .1-1.3.62-.73 1.34-1.76 2.14-1.95 1.21-.2 2.1.74 2.15 2.09v19.93a8.82 8.82 0 007.8 9.13c3 .42 3.35.8 3.35 3.85v7c0 1.53-.61 2.65-2.25 2.65s-2.23-1.12-2.23-2.67v-6.25m15.5-42.52v10.62c0 1.53-.5 2.61-2.11 2.79-1.36.15-2.29-1-2.29-2.81v-21.24c0-1.86.88-2.9 2.3-2.78 1.67.15 2.12 1.3 2.1 2.8v10.62m-26.56-11.35v-8.31c0-1.55.6-2.7 2.2-2.7s2.24 1.08 2.22 2.66v17c0 1.4-.5 2.5-2.08 2.56s-2.3-1-2.32-2.56v-8.69m11.03-12.11v-7.4c0-1.56.58-2.66 2.27-2.66s2.21 1.17 2.21 2.7v14.62c0 1.52-.6 2.6-2.18 2.8-1.4.09-2.29-1-2.3-2.83V96.8" />
    <path d="M159.16 153.91a4.5 4.5 0 11.15-9 4.66 4.66 0 014.36 4.55 4.61 4.61 0 01-4.51 4.42m8.96-58.13a23.09 23.09 0 010-2.63 2 2 0 012.17-1.88 2 2 0 012.12 1.95c.08 1.55.06 3.13 0 4.71a2 2 0 01-2.1 2.15 2 2 0 01-2.23-2c-.07-.75 0-1.52 0-2.27z" />
  </svg>
)

export default MaterialsIcon
