import React, { SVGAttributes } from "react"

import { COLORS } from "src/constants"

const ScrewIcon: React.FC<SVGAttributes<SVGElement>> = (props) => (
  <svg viewBox="0 0 260.6 260.6" fill={COLORS.light} {...props}>
    <path d="M115.22 139.06V84.13a4.58 4.58 0 00-.86-2.57c-6.78-9.08-13.59-18.08-20.36-27.18a7.16 7.16 0 01-1.33-2.49 3.7 3.7 0 013.32-4.37 9.33 9.33 0 011.06 0h66.54c1.75 0 3.18.47 4 2.1s.34 3.06-.71 4.46c-6.84 9.08-13.63 18.19-20.48 27.25a4.87 4.87 0 00-1 3.17v108.64a6 6 0 01-1.29 3.87c-3.58 4.68-7.07 9.4-10.62 14.1-2.06 2.73-4.75 2.71-6.81 0-3.45-4.58-6.93-9.14-10.28-13.79a6.32 6.32 0 01-1.15-3.43c-.07-18.26-.05-36.52-.05-54.77M122.75 97l1.43-.88c3.52-2.33 6.93-4.85 10.57-7 2.42-1.4 3.58-3 3.1-5.84-.36-2.12.48-3.83 1.78-5.53 5.41-7.06 10.7-14.2 16-21.33.29-.37.54-.78.85-1.24h-22.4c0 2.53.06 4.94 0 7.35a3.75 3.75 0 11-7.5-.1c-.05-2.4 0-4.8 0-7.27h-22.5c.39.54.65 1 .95 1.34 4.73 6.31 9.49 12.61 14.18 18.94 4.43 6 3.5 3.4 3.59 10.8v10.72m15.09-.7a2.11 2.11 0 00-.6.22c-4.61 3.06-9.22 6.11-13.78 9.23a2 2 0 00-.68 1.47c-.06 3.57 0 7.14 0 10.71v1.79c5-3.35 9.74-6.49 14.44-9.68a1.71 1.71 0 00.62-1.21V96.26m-15.09 45.8c.3-.05.41 0 .48-.09 4.64-3.09 9.3-6.17 13.9-9.31a2 2 0 00.67-1.48c.06-3.77 0-7.54 0-11.31a7.63 7.63 0 00-.16-1.06c-4.86 3.25-9.59 6.39-14.29 9.57a1.76 1.76 0 00-.61 1.22v12.46m0 22.69a2.69 2.69 0 00.57-.27c4.6-3.07 9.21-6.12 13.78-9.23a2 2 0 00.68-1.47c.06-3.47 0-6.94 0-10.42v-2.08c-5 3.34-9.75 6.48-14.45 9.67a1.71 1.71 0 00-.62 1.2v12.54m0 22.82c5-3.36 9.8-6.53 14.55-9.72a1.27 1.27 0 00.51-.82V164.2h-.43c-4.69 3.11-9.38 6.22-14 9.37a1.55 1.55 0 00-.59 1.08v12.83m7.57 15.92c2.52-3.36 4.92-6.55 7.3-9.76a1.18 1.18 0 00.23-.68v-6.38l-13.46 9 5.92 7.84" />
  </svg>
)

export default ScrewIcon
